<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button
            type="button"
            @click="showModal({ type: 'cancel' })"
            class="btn btn-inverse"
            title="Kembali"
          >
            Kembali
          </button>
        </span>
      </h3>
      <hr />

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-6 pull-left">
            <small class="text-muted">Nama Berkas</small>
            <h6>
              {{
                state.detail.namafolder === "" ? "-" : state.detail.namafolder
              }}
            </h6>
            <small class="text-muted">Keterangan</small>
            <h6>
              {{
                state.detail.keterangan === "" ? "-" : state.detail.keterangan
              }}
            </h6>
            <small class="text-muted">Klasifikasi</small>
            <h6>
              {{
                state.detail.klasifikasi === "" ? "-" : state.detail.klasifikasi
              }}
            </h6>
            <small class="text-muted">Masa Retensi</small>
            <h6>
              {{
                state.detail.retensi_aktif === ""
                  ? "-"
                  : state.detail.retensi_aktif
              }}
              Tahun Active,
              {{
                state.detail.retensi_inaktif === ""
                  ? "-"
                  : state.detail.retensi_inaktif
              }}
              Tahun Inactive
            </h6>
            <small class="text-muted">Akhir Retensi</small>
            <h6>
              {{
                state.detail.akhir_retensi === ""
                  ? "-"
                  : state.detail.akhir_retensi
              }}
            </h6>
            <small class="text-muted">Info Berkas</small>
            <h6>
              {{
                state.detail.information === "" ? "-" : state.detail.information
              }}
            </h6>
            <small class="text-muted">Aksi</small>
            <h6>
              <!-- <button type="button" @click="goMasukkanFolderDinilai()" class="btn btn-success" title="Masukkan ke Berkas Dinilai">Masukkan ke Berkas Dinilai</button> -->
              <button
                type="button"
                @click="showModal({ type: 'folderdinilai' })"
                class="btn btn-success"
                title="Masukkan ke Berkas Dinilai"
              >
                Masukkan ke Berkas Dinilai
              </button>
            </h6>
            <h6>
              <!-- <button type="button" @click="goMusnahFolder()" class="btn btn-danger" title="Musnahkan Berkas Ini">Musnahkan Berkas Ini</button> -->
              <button
                type="button"
                @click="showModal({ type: 'musnah' })"
                class="btn btn-danger"
                title="Musnahkan Berkas Ini"
              >
                Musnahkan Berkas Ini
              </button>
            </h6>
          </div>
        </div>
      </div>
      <ModalDefault
        v-show="isVisible"
        :title="modalTitle"
        :content="modalContent"
        :action="ActionChangeable"
        :confirmation="isConfirmation"
        @close="onClose"
      />
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import ImageItem from "@/components/img/imgItem";
import { gtTab, gtTabs } from "@/components/gtTabs";
export default {
  components: {
    RotateSquare5,
    ImageItem,
    gtTab,
    gtTabs,
  },
  data() {
    return {
      isTouched: false,
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
    };
  },
  computed: {
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.daftarusulpermanen;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.tanggal_diterima);
    },
    tanggaldokumen() {
      return this.formatDate(this.state.detail.tanggal_dokumen);
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("daftarusulpermanen/STATE", state);
      this.$store.dispatch(
        "daftarusulpermanen/getDaftarusulpermanenBerkasById",
        this.$route.params
      );
    },
    onClose() {
      this.isVisible = false;
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      } else if (option.type == "folderdinilai") {
        this.modalTitle = "Folder Dinilai?";
        this.modalContent = "<p>Masukkan ke Berkas Dinilai?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goMasukkanFolderDinilai;
      } else if (option.type == "musnah") {
        this.modalTitle = "Konfirmasi Musnahkan?";
        this.modalContent =
          "<p>Berkas ini terdapat " +
          this.state.detail.information +
          "</p><br/><br/>" +
          this.state.detail.dokumenarsip +
          "<p>Apakah Anda Yakin?</p>";
        // this.modalContent = this.state;
        this.isConfirmation = true;
        // this.ActionChangeable = this.goMusnahFolder;showModal({type:'cancel'})
        this.ActionChangeable = this.goKonfirmMusnahkan;
      } else if (option.type == "konfirm_musnah") {
        this.modalTitle = "Konfirmasi Musnahkan?";
        this.modalContent =
          "<p>Berkas ini terdapat " + this.state.detail.information + "?</p>";
        // this.modalContent = this.state;
        this.isConfirmation = true;
        this.ActionChangeable = this.goMusnahFolder;
        // this.ActionChangeable = this.showModal({type:'konfirm_musnah'});
      }
    },
    goKonfirmMusnahkan() {
      this.modalTitle = "Musnahkan?";
      // this.modalContent = "<p>Berkas ini terdapat "+ this.state.detail.informasi  +"</p><br/><table border='1'><tr><td>No</td><td>Agenda Number</td></tr></table><br/><p>Apakah Anda Yakin?</p>";
      // this.modalContent = "<p>Berkas ini terdapat "+ this.state.detail.information  +"</p><br/><br/><p>Apakah Anda Yakin?</p>";
      this.modalContent =
        "<p>Berkas yang dimusnahkan tidak dapat dikembalikan. Apakah Anda yakin untuk memusnahkan berkas " +
        this.state.detail.namafolder +
        "?</p>";
      this.isConfirmation = true;
      this.ActionChangeable = this.goMusnahFolder;
    },
    goBack() {
      this.$store.dispatch("daftarusulpermanen/onCancel");
    },
    goRetensi() {
      this.$store.dispatch("daftarusulpermanen/onRetensi");
    },
    goMasukkanFolderDinilai() {
      // this.$store.dispatch("daftarusulpermanen/onRetensi");
      const data = {
        id: this.$route.params.id,
        flag_folder: 8,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("daftarusulpermanen/submitFolderDinilai", data);
    },
    goPermanen() {
      const data = {
        id: this.$route.params.id,
        flag_folder: 2,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("daftarusulpermanen/submitPermanen", data);
    },
    goMusnahFolder() {
      const data = {
        id: this.$route.params.id,
        flag_folder: 5,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("daftarusulpermanen/submitMusnahFolder", data);
    },
  },
};
</script>
